import { useCallback, useEffect, useMemo } from 'react'
import { useAppSelector, useAppDispatch } from '@/lib/redux/hooks'
import { updateNodeRect } from '../../content/contentSlice'
import { setActiveTool, setDragAction } from '../canvasSlice'
import { calculateLinearSize, createSnapFunction } from '../utils'

export default function ResizeNodeController() {
	const dispatch = useAppDispatch()
	const isRectTool = useAppSelector((s) => s.canvas.activeTool === 'rectangle')
	const dragAction = useAppSelector((s) => s.canvas.dragAction)
	const selectedNodeId = useAppSelector((s) => s.canvas.selectedNodeId)
	const zoomLevel = useAppSelector((s) => s.canvas.zoomLevel)
	const snapCoords = useAppSelector((s) => s.canvas.snapCoords)

	const getHorizontalSnapValue = useMemo(
		() => createSnapFunction(snapCoords?.x || {}),
		[snapCoords?.x]
	)
	const getVerticalSnapValue = useMemo(
		() => createSnapFunction(snapCoords?.y || {}),
		[snapCoords?.y]
	)

	const handleNodeTransform = useCallback(
		(e: MouseEvent) => {
			e.stopPropagation()

			if (dragAction?.type === 'resize') {
				const { direction, anchorCoords, initDims } = dragAction.data

				// ---------------------------------------
				// Horizontal calculations
				// ---------------------------------------
				const diffX = (e.clientX - anchorCoords.x) / zoomLevel
				const horizontalHandle = direction[0]
				let isHorizontalSnap = false
				let [left, width] = calculateLinearSize(
					[initDims.left, initDims.width],
					horizontalHandle ? diffX : 0,
					horizontalHandle === 'right' ? 'outwards' : 'inwards',
					{
						specular: e.altKey,
						snapFn: (val) => {
							const res = getHorizontalSnapValue([val])
							if (typeof res.snapCoord === 'number') {
								isHorizontalSnap = true
							}
							return res.value
						},
					}
				)
				if (!isHorizontalSnap) {
					left = Math.round(left)
					width = Math.round(width)
				}

				// ---------------------------------------
				// Vertical calculations
				// ---------------------------------------
				const diffY = (e.clientY - anchorCoords.y) / zoomLevel
				const verticalHandle = direction[1]
				let isVerticalSnap = false
				let [top, height] = calculateLinearSize(
					[initDims.top, initDims.height],
					verticalHandle ? diffY : 0,
					verticalHandle === 'down' ? 'outwards' : 'inwards',
					{
						specular: e.altKey,
						snapFn: (val) => {
							const res = getVerticalSnapValue([val])
							if (typeof res.snapCoord === 'number') {
								isVerticalSnap = true
							}
							return res.value
						},
					}
				)
				if (!isVerticalSnap) {
					top = Math.round(top)
					height = Math.round(height)
				}

				if (!dragAction.initiated) {
					dispatch(
						setDragAction({
							...dragAction,
							initiated: true,
						})
					)
				}

				dispatch(
					updateNodeRect({
						nodeId: selectedNodeId!,
						position: { left, top, width, height },
					})
				)
			}
		},
		[
			dispatch,
			dragAction,
			getHorizontalSnapValue,
			getVerticalSnapValue,
			selectedNodeId,
			zoomLevel,
		]
	)

	useEffect(() => {
		if (!dragAction || dragAction.type !== 'resize') return

		function endDrag() {
			if (!dragAction) return
			dispatch(setDragAction(null))
			dispatch(setActiveTool('select'))
		}

		document.addEventListener('mousemove', handleNodeTransform)
		document.addEventListener('mouseup', endDrag)

		return () => {
			document.removeEventListener('mousemove', handleNodeTransform)
			document.removeEventListener('mouseup', endDrag)
		}
	}, [dispatch, dragAction, handleNodeTransform, isRectTool])

	return null
}
