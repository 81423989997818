import { CANVAS_ID } from '.'

export function getCanvasCoordinates(
	clientX: number,
	clientY: number,
	zoomLevel: number
): PointCoord {
	const canvas = document.getElementById(CANVAS_ID)
	if (!canvas) throw new Error('Canvas not found')
	const rect = canvas.getBoundingClientRect()

	return {
		x: (clientX - rect.left) / zoomLevel,
		y: (clientY - rect.top) / zoomLevel,
	}
}

export function createSnapFunction(
	snapCoords: SnapCoords,
	amount = 10
): (values: [margin: number, center?: number, end?: number]) => {
	/** The value after snapping */
	value: number
	roundedAmount: number
	/** The original snapCoord that was triggered (if triggered) */
	snapCoord?: number
} {
	const snapArr = Object.keys(snapCoords).map((v) => parseFloat(v))
	return (values: [margin: number, center?: number, end?: number]) => {
		for (const snapCoord of snapArr) {
			for (const value of values) {
				if (value && value > snapCoord - amount && value < snapCoord + amount) {
					const roundedAmount = value - snapCoord
					return {
						value: values[0] - roundedAmount,
						roundedAmount,
						snapCoord,
					}
				}
			}
		}
		return { value: values[0], roundedAmount: 0 }
	}
}

/**
 * Calculates the new size and margin in relation to an update in the size.
 */
export function calculateLinearSize(
	[margin, size]: SizeTuple,
	/** the difference to apply to the size */
	diff: number,
	/** whether to adjust the size outwards or inwards (reducing the margin) */
	direction: 'outwards' | 'inwards',
	options: {
		/** Whether to resize specularly in the opposite direction (similarly to what happens in Figma if you press alt when resizing.) */
		specular?: boolean
		/** A function to snap the size to a custom value */
		snapFn?: (val: number) => number
	}
): SizeTuple {
	let newSize = size
	let newMargin = margin
	const snapFn = options.snapFn || (() => diff)

	if (direction === 'outwards') {
		const snappedDiff = snapFn(size + margin + diff) - (size + margin)
		if (options.specular) {
			newSize = size + snappedDiff * 2
			newMargin = margin - snappedDiff
		} else {
			newSize = size + snappedDiff
		}
	}
	// Inwards
	else {
		const snappedDiff = snapFn(margin + diff) - margin
		newMargin = margin + snappedDiff
		if (options.specular) {
			newSize = size - snappedDiff * 2
		} else {
			newSize = size - snappedDiff
		}
	}

	// If the size is negative, reverse the direction
	if (newSize < 0) {
		newSize = -newSize
		newMargin = newMargin - newSize
	}

	return [newMargin, newSize]
}
