import { createSlice } from '@reduxjs/toolkit'

type PreferencesSlice = {
	showInterface: boolean
}

const initialState: PreferencesSlice = {
	showInterface: true,
}

export const preferencesSlice = createSlice({
	name: 'preferences',
	initialState,
	reducers: {
		toggleShowInterface: (state) => {
			state.showInterface = !state.showInterface
		},
	},
})

export const { toggleShowInterface } = preferencesSlice.actions
