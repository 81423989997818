import { useEffect, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { deleteNode, updateNodeRect } from '../../content/contentSlice'
import { useAppDispatch, useAppSelector } from '@/lib/redux/hooks'
import { setDragAction } from '../canvasSlice'
import { createSnapFunction, getCanvasCoordinates } from '../utils'
import { useGetNodePosition } from '../useGetNodePosition'

export default function MoveNodeController() {
	const dispatch = useAppDispatch()
	const { selectedNodeId, dragAction, snapCoords } = useAppSelector(
		(s) => s.canvas
	)
	const node = useAppSelector(
		(s) => selectedNodeId && s.content.nodes[selectedNodeId]
	)
	const zoomLevel = useAppSelector((s) => s.canvas.zoomLevel)
	const nodeDims = useGetNodePosition(selectedNodeId)

	const getHorizontalSnapValue = useMemo(
		() => createSnapFunction(snapCoords?.x || {}, 10 / zoomLevel),
		[snapCoords?.x, zoomLevel]
	)
	const getVerticalSnapValue = useMemo(
		() => createSnapFunction(snapCoords?.y || {}, 10 / zoomLevel),
		[snapCoords?.y, zoomLevel]
	)

	useHotkeys(
		'del, delete, backspace',
		() => {
			if (selectedNodeId) {
				dispatch(deleteNode({ nodeId: selectedNodeId }))
			}
		},
		{ enabled: !!selectedNodeId }
	)

	useEffect(() => {
		let rafId: number
		let lastMouseEvent: MouseEvent | null = null

		function updatePreview() {
			if (!lastMouseEvent || !nodeDims) return
			const e = lastMouseEvent
			const mouseCanvasCoords = getCanvasCoordinates(
				e.clientX,
				e.clientY,
				zoomLevel
			)

			if (!dragAction || dragAction.type !== 'move' || !selectedNodeId) return
			e.stopPropagation()

			const { dropContext, dragTarget } = dragAction.data

			// calculate the new position (all values are in canvas coordinates / zoom adjusted)
			let left =
				mouseCanvasCoords.x -
				dropContext.canvasCoords.x -
				dragTarget.mouseCoords.x
			let top =
				mouseCanvasCoords.y -
				dropContext.canvasCoords.y -
				dragTarget.mouseCoords.y

			// apply snapping
			const horizontalSnap = getHorizontalSnapValue([
				left,
				left + nodeDims.width / 2,
				left + nodeDims.width,
			])
			left =
				typeof horizontalSnap.snapCoord === 'number'
					? horizontalSnap.value
					: Math.round(horizontalSnap.value)

			const verticalSnap = getVerticalSnapValue([
				top,
				top + nodeDims.height / 2,
				top + nodeDims.height,
			])
			top =
				typeof verticalSnap.snapCoord === 'number'
					? verticalSnap.value
					: Math.round(verticalSnap.value)

			if (!dragAction.initiated) {
				dispatch(
					setDragAction({
						...dragAction,
						initiated: true,
					})
				)
			}

			dispatch(
				updateNodeRect({
					nodeId: selectedNodeId,
					position: { left, top },
					parentId: dropContext.nodeId,
				})
			)

			lastMouseEvent = null
		}

		function previewMoveNode(e: MouseEvent) {
			lastMouseEvent = e
			if (!rafId) {
				rafId = requestAnimationFrame(() => {
					updatePreview()
					rafId = 0
				})
			}
		}

		document.addEventListener('mousemove', previewMoveNode)
		return () => {
			document.removeEventListener('mousemove', previewMoveNode)
			if (rafId) {
				cancelAnimationFrame(rafId)
			}
		}
	}, [
		dispatch,
		dragAction,
		getHorizontalSnapValue,
		getVerticalSnapValue,
		nodeDims,
		selectedNodeId,
		zoomLevel,
	])

	useEffect(() => {
		if (!dragAction || dragAction.type !== 'move') return

		const removeDrag = () => {
			dispatch(setDragAction(null))
		}
		document.addEventListener('mouseup', removeDrag)

		return () => {
			document.removeEventListener('mouseup', removeDrag)
		}
	}, [dispatch, dragAction])

	return null
}
