import { useState, useEffect } from 'react'
import { CANVAS_ID, CANVAS_ORIGIN } from '.'

export function useGetNodeCanvasCoords(
	nodeId: NodeId | typeof CANVAS_ID | undefined,
	zoomLevel: number
) {
	const [nodeCanvasCoords, setNodeCanvasCoords] = useState<{
		top: number
		left: number
		width: number
		height: number
	} | null>(null)

	useEffect(() => {
		if (!nodeId) {
			setNodeCanvasCoords(null)
		}
		// Canvas itself
		else if (nodeId === CANVAS_ID) {
			setNodeCanvasCoords({
				top: CANVAS_ORIGIN,
				left: CANVAS_ORIGIN,
				width: 0,
				height: 0,
			})
			return
		}
		// Other nodes
		else {
			const { canvasX, canvasY } = getCanvasClientPosition()
			const ref = document.getElementById(`preview_${nodeId}`)
			if (!ref) throw Error('Could not find node')
			const { top, left, width, height } = ref.getBoundingClientRect()
			setNodeCanvasCoords({
				top: (top - canvasY) / zoomLevel,
				left: (left - canvasX) / zoomLevel,
				width: width / zoomLevel,
				height: height / zoomLevel,
			})
		}
	}, [nodeId, zoomLevel])

	return nodeCanvasCoords
}

function getCanvasClientPosition() {
	const rect = document.getElementById(CANVAS_ID)?.getBoundingClientRect()

	if (!rect) throw Error('Could not determine canvas rect')

	return {
		canvasX: rect.left,
		canvasY: rect.top,
	}
}
