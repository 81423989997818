import { useAppDispatch, useAppSelector } from '@/lib/redux/hooks'
import { useEffect } from 'react'
import { zoomIn, zoomOut, zoomBy, resetZoomLevel } from './canvasSlice'
import { useHotkeys } from 'react-hotkeys-hook'

export default function ZoomToolController() {
	const dispatch = useAppDispatch()
	const activeTool = useAppSelector((s) => s.canvas.activeTool)

	useHotkeys('mod+0', () => dispatch(resetZoomLevel()))

	useEffect(() => {
		if (activeTool !== 'zoom') return

		const handleZoomClick = (e: MouseEvent) => {
			e.stopPropagation()
			if (e.altKey) {
				dispatch(zoomOut())
			} else {
				dispatch(zoomIn())
			}
		}
		document.addEventListener('mouseup', handleZoomClick)

		return () => {
			document.removeEventListener('mouseup', handleZoomClick)
		}
	}, [activeTool, dispatch])

	// Add wheel zoom functionality with continuous zoom
	useEffect(() => {
		const handleWheel = (e: WheelEvent) => {
			// Check if CMD (Mac) or CTRL (Windows) is pressed
			if (e.metaKey || e.ctrlKey) {
				e.preventDefault()
				const zoomSensitivity = 0.001 // Adjust this value for sensitivity
				const zoomFactor = 1 - e.deltaY * zoomSensitivity
				dispatch(zoomBy(zoomFactor))
			}
		}

		// Add the event listener with passive: false to allow preventDefault
		document.addEventListener('wheel', handleWheel, { passive: false })

		return () => {
			document.removeEventListener('wheel', handleWheel)
		}
	}, [dispatch])

	return null
}
