import { useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from './store'
import {
	type Action,
	createAsyncThunk,
	type ThunkAction,
} from '@reduxjs/toolkit'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()

type AsyncAppThunk = {
	state: RootState
	dispatch: AppDispatch
	rejectValue: string
}

export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>

export const createAppAsyncThunk = createAsyncThunk.withTypes<AsyncAppThunk>()
