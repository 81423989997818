import { useAppSelector } from '@/lib/redux/hooks'
import { useGetNodeCanvasCoords } from './useGetNodeCanvasCoords'
import { useGetNodePosition } from './useGetNodePosition'

export default function SnapGuidesHighlighter() {
	const selectedNodeId = useAppSelector((s) => s.canvas.selectedNodeId)
	const selectedNode = useAppSelector(
		(s) => s.content.nodes[s.canvas.selectedNodeId || '']
	)
	const snapCoords = useAppSelector((s) => s.canvas.snapCoords)
	const zoomLevel = useAppSelector((s) => s.canvas.zoomLevel)
	const parentCanvasCoords = useGetNodeCanvasCoords(
		selectedNode?.parentId,
		zoomLevel
	)
	const isMouseActionInitiated = useAppSelector(
		(s) => s.canvas.dragAction?.initiated
	)
	const position = useGetNodePosition(selectedNodeId)

	return (
		isMouseActionInitiated &&
		position && (
			<div
				className='absolute pointer-events-none z-10'
				style={{
					top: parentCanvasCoords?.top,
					left: parentCanvasCoords?.left,
					width: parentCanvasCoords?.width,
					height: parentCanvasCoords?.height,
				}}
			>
				{Object.values(snapCoords?.x || {}).map(({ value }) => (
					<div
						key={selectedNode.parentId + 'H' + value}
						className='absolute w-px h-full bg-green-500'
						style={{
							left: value,
							opacity:
								position.left === value ||
								position.width / 2 + position.left === value ||
								position.left + position.width === value
									? 1
									: 0,
						}}
					/>
				))}
				{Object.values(snapCoords?.y || {}).map(({ value }) => (
					<div
						key={selectedNode.parentId + 'V' + value}
						className='absolute h-px w-full bg-green-500'
						style={{
							top: value,
							opacity:
								position.top === value ||
								position.height / 2 + position.top === value ||
								position.top + position.height === value
									? 1
									: 0,
						}}
					/>
				))}
			</div>
		)
	)
}
