import { useEffect, useCallback } from 'react'
import { CANVAS_ID, CANVAS_ORIGIN } from '.'
import {
	generateSnapCoords,
	setDragAction,
	setSelectedNode,
	setSnapCoords,
} from './canvasSlice'
import { AppThunk, useAppDispatch, useAppSelector } from '@/lib/redux/hooks'
import { getCanvasCoordinates } from './utils'

export function CanvasEventListener() {
	const dispatch = useAppDispatch()
	const isMoveAction = useAppSelector(
		(s) => s.canvas.dragAction?.type === 'move'
	)

	const onMouseDownHandler = useCallback(
		(e: MouseEvent) => {
			const target = e.target as HTMLElement
			const nodeId = target.getAttribute('data-node-id')
			if (!nodeId || !isNodeId(nodeId)) return
			e.stopPropagation()

			const isDoubleClick = e.detail === 2

			dispatch(
				setSelectedNode({ id: nodeId, isDeepEditingMode: isDoubleClick })
			)

			if (isDoubleClick) return

			dispatch(startMoveAction(nodeId, e))
		},
		[dispatch]
	)

	const onMouseOverHandler = useCallback(
		(e: MouseEvent) => {
			if (!isMoveAction) return

			const target = e.target as HTMLElement
			const nodeId = target.getAttribute('data-node-id')

			if (!nodeId || (!isNodeId(nodeId) && nodeId !== CANVAS_ID)) return
			e.stopPropagation()
			e.preventDefault()

			dispatch(updateMoveActionDropContext(nodeId, e))
		},
		[dispatch, isMoveAction]
	)

	useEffect(() => {
		console.log('assigning events')

		const canvas = document.getElementById(CANVAS_ID)
		canvas?.addEventListener('mousedown', onMouseDownHandler)
		canvas?.addEventListener('pointerover', onMouseOverHandler)

		return () => {
			canvas?.removeEventListener('mousedown', onMouseDownHandler)
			canvas?.removeEventListener('pointerover', onMouseOverHandler)
		}
	}, [onMouseDownHandler, onMouseOverHandler])

	return null
}

const startMoveAction =
	(nodeId: NodeId, mouseEvent: MouseEvent): AppThunk =>
	(dispatch, getState) => {
		const zoomLevel = getState().canvas.zoomLevel
		const node = getState().content.nodes[nodeId]

		if (node && node.parentId !== CANVAS_ID) {
			dispatch(generateSnapCoords(node.parentId))
		}

		const mouseCanvasCoords = getCanvasCoordinates(
			mouseEvent.clientX,
			mouseEvent.clientY,
			zoomLevel
		)

		// not amazing, but probably we don't need it for Component nodes anyway
		const dropContextCanvasCoords =
			'position' in node
				? {
						x: mouseCanvasCoords.x - mouseEvent.offsetX - node.position.left,
						y: mouseCanvasCoords.y - mouseEvent.offsetY - node.position.top,
				  }
				: {
						x: 0,
						y: 0,
				  }

		dispatch(
			setDragAction({
				type: 'move',
				initiated: false,
				data: {
					dragTarget: {
						nodeId,
						mouseCoords: { x: mouseEvent.offsetX, y: mouseEvent.offsetY },
					},
					dropContext: {
						nodeId: node.parentId,
						canvasCoords: dropContextCanvasCoords,
					},
				},
			})
		)
	}

const updateMoveActionDropContext =
	(nodeId: NodeOrCanvasId, e: MouseEvent): AppThunk =>
	(dispatch, getState) => {
		const zoomLevel = getState().canvas.zoomLevel
		const dragAction = getState().canvas.dragAction
		const node = getState().content.nodes[nodeId]

		if (!dragAction || dragAction.type !== 'move')
			throw new Error('No move action')

		if (
			dragAction.data.dropContext.nodeId === nodeId ||
			(nodeId !== CANVAS_ID && node.type !== 'frame')
		)
			return

		const mouseCanvasCoords = getCanvasCoordinates(
			e.clientX,
			e.clientY,
			zoomLevel
		)

		const isCanvas = nodeId === CANVAS_ID

		const dropContextCanvasCoords = {
			x: mouseCanvasCoords.x - e.offsetX + (isCanvas ? CANVAS_ORIGIN : 0),
			y: mouseCanvasCoords.y - e.offsetY + (isCanvas ? CANVAS_ORIGIN : 0),
		}

		const updatedDragAction = {
			...dragAction,
			data: {
				...dragAction.data,
				dropContext: {
					nodeId,
					canvasCoords: dropContextCanvasCoords,
				},
			},
		}

		dispatch(setDragAction(updatedDragAction))

		if (nodeId === CANVAS_ID) dispatch(setSnapCoords(null))
		else dispatch(generateSnapCoords(nodeId))
	}

function isNodeId(value: string): value is NodeId {
	return value.startsWith('node_')
}
