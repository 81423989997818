import { Button } from '@/components/ui/button'
import { useAppDispatch, useAppSelector } from '@/lib/redux/hooks'
import { forwardRef, useEffect } from 'react'
import { RefObject } from 'react'
import { setComputedNodeDims } from './canvasSlice'
import { cn } from '@/lib/utils'

type Props = {
	node: ComponentCanvasNode
}

const RenderReactNode = forwardRef<HTMLDivElement, Props>(({ node }, ref) => {
	const dispatch = useAppDispatch()
	const isDragging = useAppSelector(
		(s) =>
			s.canvas.dragAction?.type === 'move' &&
			s.canvas.dragAction?.data.dragTarget.nodeId === node.id
	)
	RenderReactNode.displayName = 'RenderReactNode'

	useEffect(() => {
		if (ref && 'current' in ref) {
			positionObserver.observe(ref, (dims) => {
				dispatch(setComputedNodeDims({ [node.id]: dims }))
			})

			return () => {
				positionObserver.unobserve(ref)
			}
		}
	}, [ref, node.type, dispatch, node.id])

	return (
		<div
			ref={ref}
			data-node-id={node.id}
			className={cn('*:pointer-events-none', isDragging && 'opacity-70')}
		>
			<Button variant='default' className='w-full'>
				Sample Button
			</Button>
		</div>
	)
})

export default RenderReactNode

class PositionObserver {
	private observers: Map<string, MutationObserver>

	constructor() {
		this.observers = new Map()
	}

	observe(
		ref: RefObject<HTMLDivElement>,
		callback: (dims: ElementDims) => void
	) {
		const observer = new ResizeObserver(() => {
			if (!ref.current) return
			const id = ref.current.id
			console.log(
				id.substring(15, 21),
				ref.current.offsetLeft,
				ref.current.offsetWidth
			)
			callback({
				top: ref.current.offsetTop,
				left: ref.current.offsetLeft,
				height: ref.current.offsetHeight,
				width: ref.current.offsetWidth,
			})
		})

		if (!ref.current) return
		observer.observe(ref.current)
	}

	unobserve(ref: RefObject<HTMLDivElement>) {
		if (!ref.current) return
		const observer = this.observers.get(ref.current.id)
		if (!observer) return
		observer.disconnect()
	}
}

const positionObserver = new PositionObserver()
