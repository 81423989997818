import { useRef } from 'react'
import MoveNodeController from './cursor-tool/MoveNodeController'
import ResizeNodeController from './cursor-tool/ResizeNodeController'
import ZoomToolController from './ZoomToolController'
import TransformBox from './TransformBox'
import { useAppSelector } from '@/lib/redux/hooks'
import { useAppDispatch } from '@/lib/redux/hooks'
import clsx from 'clsx'
import { setSelectedNode } from './canvasSlice'
import useCreateChildNode from './useCreateChildNode'
import RenderFrameNode from './RenderFrameNode'
import SnapGuidesHighlighter from './SnapGuidesHighlighter'
import RenderReactNode from './RenderReactNode'
import RenderTextNode from './RenderTextNode'
import { CanvasEventListener } from './CanvasEventListener'
import { updateNode } from '../content/contentSlice'

const CANVAS_SIZE = 3000
/** Where to place the X:0 and Y:0 of the canvas */
export const CANVAS_ORIGIN = 1000
export const CANVAS_ID: CanvasId = 'canvas'

export default function Canvas() {
	const dispatch = useAppDispatch()
	const canvasRef = useRef<HTMLDivElement>(null)
	const rootNodes = useAppSelector((s) => s.content.rootNodes)
	const zoomLevel = useAppSelector((s) => s.canvas.zoomLevel)
	const activeTool = useAppSelector((s) => s.canvas.activeTool)
	useCreateChildNode(canvasRef, CANVAS_ID)

	return (
		<div
			ref={canvasRef}
			id={CANVAS_ID}
			data-node-id={CANVAS_ID}
			style={{
				width: CANVAS_SIZE,
				height: CANVAS_SIZE,
				// TODO: potentially I can update this via ref to prevent re-rendering
				transform: `scale(${zoomLevel})`,
			}}
			className={clsx(
				'bg-zinc-100 overflow-hidden',
				activeTool === 'rectangle' && 'cursor-crosshair',
				activeTool === 'text' && 'cursor-text'
			)}
			onMouseDown={() => {
				if (activeTool === 'select') {
					dispatch(setSelectedNode(null))
				}
			}}
		>
			<CanvasEventListener />
			{/* Event Listeners */}
			<MoveNodeController />
			<ResizeNodeController />
			<ZoomToolController />

			{/* Content */}
			{rootNodes.map((nodeId) => (
				<CanvasNode key={nodeId} nodeId={nodeId} isRoot />
			))}

			{/* Canvas UI elements */}
			<SnapGuidesHighlighter />
			<TransformBox />
		</div>
	)
}

function CanvasNode(props: { nodeId: NodeId; isRoot?: boolean }) {
	const dispatch = useAppDispatch()
	const ref = useRef<HTMLDivElement>(null)
	const node = useAppSelector((s) => s.content.nodes[props.nodeId])
	const isDragging = useAppSelector(
		(s) =>
			s.canvas.dragAction?.type === 'move' &&
			s.canvas.dragAction.data.dragTarget.nodeId === props.nodeId
	)
	const isDropContext = useAppSelector(
		(s) =>
			s.canvas.dragAction?.type === 'move' &&
			s.canvas.dragAction.data.dropContext.nodeId === props.nodeId
	)
	const isSelected = useAppSelector(
		(s) => s.canvas.selectedNodeId === props.nodeId
	)
	const isDeepEditingMode = useAppSelector((s) => s.canvas.isDeepEditingMode)
	useCreateChildNode(ref, props.nodeId)

	if (ref.current) {
		ref.current.id = `preview_${props.nodeId}`
	}

	if (node.type === 'component')
		return <RenderReactNode node={node} ref={ref} />

	if (node.type === 'frame')
		return (
			<RenderFrameNode
				ref={ref}
				node={node}
				isPositionedOnCanvas={!!props.isRoot}
				className={clsx(
					isDragging && 'pointer-events-none',
					isDropContext && 'outline outline-1 outline-blue-500'
				)}
			>
				{node.childrenIds.map((childNodeId) => (
					<CanvasNode key={childNodeId} nodeId={childNodeId} />
				))}
			</RenderFrameNode>
		)

	if (node.type === 'text') {
		return (
			<RenderTextNode
				node={node}
				ref={ref}
				isPositionedOnCanvas={!!props.isRoot}
				className={clsx(isDragging && 'pointer-events-none')}
				editingMode={isSelected && isDeepEditingMode}
				onUpdateText={(text) => {
					dispatch(updateNode({ ...node, content: text }))
				}}
			/>
		)
	}
}
