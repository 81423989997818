import { AppThunk } from '@/lib/redux/hooks'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { CANVAS_ID } from '.'

type CanvasSlice = {
	activeTool: ToolType
	selectedNodeId: NodeId | null
	zoomLevel: number
	dragAction: null | DragAction
	snapCoords: null | { x: SnapCoords; y: SnapCoords }
	computedNodeDims: {
		[nodeId: NodeId]: ElementDims
	}
	/** Like editing text or vectors */
	isDeepEditingMode: boolean
}

type MoveAction = BuildDragAction<
	'move',
	{
		dragTarget: { nodeId: NodeId; mouseCoords: PointCoord }
		dropContext: { nodeId: CanvasNode['parentId']; canvasCoords: PointCoord }
	}
>

type ResizeAction = BuildDragAction<
	'resize',
	{
		direction: DirectionEnum
		anchorCoords: PointCoord
		initDims: ElementDims
		nodeId: NodeId
	}
>

type DragAction = MoveAction | ResizeAction

const initialState: CanvasSlice = {
	activeTool: 'select',
	selectedNodeId: null,
	zoomLevel: 1,
	dragAction: null,
	snapCoords: null,
	computedNodeDims: {},
	isDeepEditingMode: false,
}

// Define minimum and maximum zoom levels
const MIN_ZOOM = 0.5
const MAX_ZOOM = 3

const canvasSlice = createSlice({
	name: 'canvas',
	initialState,
	reducers: {
		setActiveTool: (state, action: PayloadAction<ToolType>) => {
			state.activeTool = action.payload
		},
		setSelectedNode: (
			state,
			action: PayloadAction<null | { id: NodeId; isDeepEditingMode?: boolean }>
		) => {
			state.selectedNodeId = action.payload?.id ?? null
			state.isDeepEditingMode = action.payload?.isDeepEditingMode ?? false
			if (action.payload?.id === null) state.snapCoords = null
		},
		setDragAction: (state, action: PayloadAction<DragAction | null>) => {
			state.dragAction = action.payload
		},
		setZoomLevel: (state, action: PayloadAction<number>) => {
			state.zoomLevel = action.payload
		},
		resetZoomLevel: (state) => {
			state.zoomLevel = 1
		},
		zoomIn: (state) => {
			state.zoomLevel += 0.1
		},
		zoomOut: (state) => {
			state.zoomLevel -= 0.1
		},
		zoomBy: (state, action: PayloadAction<number>) => {
			const newZoomLevel = Math.min(
				Math.max(state.zoomLevel * action.payload, MIN_ZOOM),
				MAX_ZOOM
			)
			state.zoomLevel = Math.round(newZoomLevel * 100) / 100
		},
		setSnapCoords: (
			state,
			action: PayloadAction<CanvasSlice['snapCoords']>
		) => {
			state.snapCoords = action.payload
		},
		setComputedNodeDims: (
			state,
			action: PayloadAction<CanvasSlice['computedNodeDims']>
		) => {
			state.computedNodeDims = action.payload
		},
	},
})

export const {
	setActiveTool,
	setSelectedNode,
	setDragAction,
	setZoomLevel,
	resetZoomLevel,
	zoomIn,
	zoomOut,
	setSnapCoords,
	zoomBy,
	setComputedNodeDims,
} = canvasSlice.actions
export default canvasSlice.reducer

type BuildDragAction<T extends string, D extends object> = {
	type: T
	/** We prepare the drag action and store the required data on 'mousedown' event, but it's considered initiated only when the mouse is moved */
	initiated: boolean
	data: D
}

export const generateSnapCoords =
	(parentId: NodeId): AppThunk =>
	(dispatch, getState) => {
		const state = getState()
		const { nodes } = state.content
		const { selectedNodeId } = state.canvas

		if (!selectedNodeId || nodes[selectedNodeId].type === 'component') {
			dispatch(setSnapCoords(null))
			return
		}

		// Create snap coords
		const parentNode = nodes[parentId] as FrameCanvasNode
		if (!parentNode) {
			console.error('Parent node not found')
			return
		}

		const siblingNodes = parentNode.childrenIds
			.filter((id) => id !== selectedNodeId)
			.map((id) => nodes[id])
			.filter((v) => !!v)

		const snapNodes = [
			...siblingNodes,
			{
				...parentNode,
				position: { ...parentNode.position, top: 0, left: 0 },
			},
		]
		const xCoords = getSnapCoords(snapNodes, 'x')
		const yCoords = getSnapCoords(snapNodes, 'y')

		dispatch(setSnapCoords({ x: xCoords, y: yCoords }))
	}

function getSnapCoords(nodes: CanvasNode[], dir: 'x' | 'y') {
	const snapCoords = nodes.reduce<SnapCoords>((acc, node) => {
		if (node.type !== 'frame') return acc
		const margin = dir === 'x' ? node.position.left : node.position.top
		const size = dir === 'x' ? node.position.width : node.position.height
		const nodeRectCoords = calculateNodeRectCoords(margin, size)

		acc[nodeRectCoords.start] = {
			nodeId: node.id,
			value: nodeRectCoords.start,
		}
		acc[nodeRectCoords.center] = {
			nodeId: node.id,
			value: nodeRectCoords.center,
		}
		acc[nodeRectCoords.end] = {
			nodeId: node.id,
			value: nodeRectCoords.end,
		}
		return acc
	}, {})
	return snapCoords
}

function calculateNodeRectCoords(margin: number, size: number) {
	return {
		start: margin,
		center: margin + size / 2,
		end: margin + size,
	}
}
