import React, { forwardRef } from 'react'
import { assembleBorder } from '@/modules/content/utils/style'
import { CANVAS_ORIGIN } from '@/modules/canvas'

type Props = {
	children: React.ReactNode
	node: FrameCanvasNode
	isPositionedOnCanvas: boolean
	style?: React.CSSProperties
	onMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void
	onMouseOver?: (e: React.MouseEvent<HTMLDivElement>) => void
	className?: string
}

const RenderFrameNode = forwardRef<HTMLDivElement, Props>((props, ref) => {
	RenderFrameNode.displayName = `RenderFrameNode_${props.node.id}`

	return (
		<div
			ref={ref}
			data-node-id={props.node.id}
			style={{
				position: 'absolute',
				...(props.isPositionedOnCanvas
					? {
							top: CANVAS_ORIGIN + props.node.position.top,
							left: CANVAS_ORIGIN + props.node.position.left,
					  }
					: {
							top: props.node.position.top,
							left: props.node.position.left,
					  }),
				width: props.node.position.width,
				height: props.node.position.height,
				overflow: props.node.style.clipContent ? 'hidden' : undefined,
				backgroundColor: props.node.style.backgroundColor?.value,
				border: assembleBorder(props.node.style.border),
				borderRadius: props.node.style.cornerRadius,
				...(props.style || {}),
			}}
			onMouseDown={props.onMouseDown}
			onMouseOver={props.onMouseOver}
			className={props.className}
			data-testid={props.node.name}
		>
			{props.children}
		</div>
	)
})

export default RenderFrameNode
