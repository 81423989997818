import { useAppSelector } from '@/lib/redux/hooks'

export function useGetNodePosition(nodeId: NodeId | null) {
	const node = useAppSelector((s) => nodeId && s.content.nodes[nodeId])
	const computedNodeDims = useAppSelector(
		(s) => nodeId && s.canvas.computedNodeDims[nodeId]
	)

	if (!node) return null
	if (node.type === 'component' && !computedNodeDims) return null

	return node?.type === 'component' ? computedNodeDims : node.position
}
