import React, { forwardRef, useEffect, useState } from 'react'
import { CANVAS_ORIGIN } from '@/modules/canvas'
import clsx from 'clsx'

type Props = {
	node: TextCanvasNode
	isPositionedOnCanvas: boolean
	className?: string
	editingMode?: boolean
	onUpdateText?: (text: string) => void
}

const RenderTextNode = forwardRef<HTMLParagraphElement, Props>((props, ref) => {
	RenderTextNode.displayName = `RenderTextNode_${props.node.id}`

	useEffect(() => {
		if (ref && 'current' in ref && ref.current) {
			if (
				'innerText' in ref.current &&
				ref.current.innerText !== props.node.content
			) {
				ref.current!.innerText = props.node.content
			}

			if (props.editingMode) {
				ref.current!.focus()
			}
		}
	})

	return (
		<p
			ref={ref}
			data-node-id={props.node.id}
			contentEditable={props.editingMode}
			suppressContentEditableWarning
			onInput={(e) => {
				props.onUpdateText?.(e.currentTarget.innerText)
			}}
			style={{
				position: 'absolute',
				...(props.isPositionedOnCanvas
					? {
							top: CANVAS_ORIGIN + props.node.position.top,
							left: CANVAS_ORIGIN + props.node.position.left,
					  }
					: {
							top: props.node.position.top,
							left: props.node.position.left,
					  }),
				width: props.node.position.width,
				height: props.node.position.height,
				fontSize: props.node.style.fontSize,
				fontWeight: props.node.style.fontWeight,
				fontFamily: props.node.style.fontFamily,
				color: props.node.style.color.value,
				lineHeight: props.node.style.lineHeight.value,
			}}
			className={clsx('focus-visible:outline-none', props.className)}
			data-testid={props.node.name}
		>
			{/* Don't set the content here, it will be set by the useEffect hook */}
		</p>
	)
})

export default RenderTextNode
