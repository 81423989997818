import { setDragAction } from './canvasSlice'
import { useAppDispatch, useAppSelector } from '@/lib/redux/hooks'
import { useGetNodeCanvasCoords } from './useGetNodeCanvasCoords'
import { useGetNodePosition } from './useGetNodePosition'

export default function TransformBox() {
	const dispatch = useAppDispatch()
	const node = useAppSelector(
		(s) => s.content.nodes[s.canvas.selectedNodeId || '']
	)
	const zoomLevel = useAppSelector((s) => s.canvas.zoomLevel)
	const parentCanvasCoords = useGetNodeCanvasCoords(node?.parentId, zoomLevel)
	const nodePosition = useGetNodePosition(node?.id)
	const dragAction = useAppSelector((s) => s.canvas.dragAction)
	const isDeepEditingMode = useAppSelector((s) => s.canvas.isDeepEditingMode)
	const canTransform =
		(!dragAction || dragAction.type === 'resize') && !isDeepEditingMode

	function onMouseDownHandler(e: React.MouseEvent, dirEnum: DirectionEnum) {
		e.stopPropagation()
		if (!node || !('position' in node)) return

		dispatch(
			setDragAction({
				type: 'resize',
				initiated: false,
				data: {
					direction: dirEnum,
					anchorCoords: { x: e.clientX, y: e.clientY },
					initDims: {
						top: node.position.top,
						left: node.position.left,
						width: node.position.width,
						height: node.position.height,
					},
					nodeId: node.id,
				},
			})
		)
	}

	if (!nodePosition) return null

	const zoomAdjustedWidth = nodePosition.width * zoomLevel
	const zoomAdjustedHeight = nodePosition.height * zoomLevel

	return (
		parentCanvasCoords &&
		node &&
		nodePosition && (
			<div
				className='absolute border border-blue-500 pointer-events-none'
				style={{
					top: parentCanvasCoords.top + nodePosition.top,
					left: parentCanvasCoords.left + nodePosition.left,
					width: zoomAdjustedWidth,
					height: zoomAdjustedHeight,
					transform: `scale(${1 / zoomLevel})`,
					transformOrigin: 'top left',
				}}
			>
				<div
					className='absolute flex flex-col'
					style={{
						width: zoomAdjustedWidth + 16 + 'px',
						height: zoomAdjustedHeight + 16 + 'px',
						top: -9 + 'px',
						left: -9 + 'px',
					}}
				>
					<div className='flex h-4 w-full top-0'>
						<CornerHandle
							direction='cursor-nw-resize'
							onMouseDown={(e) => onMouseDownHandler(e, ['left', 'up'])}
							canTransform={canTransform}
						/>
						<div
							className=' grow h-full cursor-ns-resize'
							style={{ pointerEvents: canTransform ? 'auto' : 'none' }}
							onMouseDown={(e) => onMouseDownHandler(e, [null, 'up'])}
						/>
						<CornerHandle
							direction='cursor-ne-resize'
							onMouseDown={(e) => onMouseDownHandler(e, ['right', 'up'])}
							canTransform={canTransform}
						/>
					</div>
					<div className='flex grow w-full'>
						<div
							className='w-4 h-full cursor-ew-resize'
							style={{ pointerEvents: canTransform ? 'auto' : 'none' }}
							onMouseDown={(e) => onMouseDownHandler(e, ['left', null])}
						/>
						<div
							className='bg-none h-full grow'
							style={{ pointerEvents: 'none' }}
						/>
						<div
							className='w-4 h-full cursor-ew-resize'
							style={{ pointerEvents: canTransform ? 'auto' : 'none' }}
							onMouseDown={(e) => onMouseDownHandler(e, ['right', null])}
						/>
					</div>
					<div className='flex h-4 w-full bottom-0'>
						<CornerHandle
							direction='cursor-sw-resize'
							onMouseDown={(e) => onMouseDownHandler(e, ['left', 'down'])}
							canTransform={canTransform}
						/>
						<div
							className='grow h-full cursor-ns-resize'
							style={{ pointerEvents: canTransform ? 'auto' : 'none' }}
							onMouseDown={(e) => onMouseDownHandler(e, [null, 'down'])}
						/>
						<CornerHandle
							direction='cursor-se-resize'
							onMouseDown={(e) => onMouseDownHandler(e, ['right', 'down'])}
							canTransform={canTransform}
						/>
					</div>
				</div>
			</div>
		)
	)
}

const CornerHandle = (props: {
	direction:
		| 'cursor-nw-resize'
		| 'cursor-ne-resize'
		| 'cursor-sw-resize'
		| 'cursor-se-resize'
	onMouseDown: (e: React.MouseEvent) => void
	canTransform?: boolean
}) => {
	return (
		<div
			className={`w-4 h-full flex justify-center items-center bg-opacity-50 ${props.direction}`}
			style={{
				pointerEvents: props.canTransform ? 'auto' : 'none',
				opacity: props.canTransform ? 1 : 0,
			}}
			onMouseDown={props.onMouseDown}
		>
			<div className='w-2 h-2 border border-blue-500 bg-white' />
		</div>
	)
}
